<template>
  <div class="modal" :class="{'is-active': opened}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Pull Sales for Dates</p>
        <button class="delete" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Select Date</label>
          <div class="control">
            <datepicker v-model="selectedDate" :inline="true" name="uniquename"></datepicker>
          </div>
        </div>

        <div v-if="error" class="notification is-danger is-light">
          <button class="delete" @click="error=''"></button>
          {{error}}
        </div>
      </section>
      <footer class="modal-card-foot">
        <a class="button is-link" :class="{'is-loading': waiting}" :disabled="!selectedDate" @click="pullSales">Pull</a>
        <a class="button" @click="close">Cancel</a>
        <p class="help" :class="{'is-success': done, 'is-info': !done}" v-if="progress">{{progress}}</p>
      </footer>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import dateFormat from 'dateformat'

export default {
  name: 'pull-sales-modal',
  components: {
    Datepicker
  },
  props: ['opened'],
  data () {
    return {
      error: '',
      waiting: false,
      selectedDate: null,
      limit: 10,
      offset: 0,
      totalResults: 0,
      progress: null,
      done: false,
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
  },
  methods: {
    close () {
      this.$emit('closePullSalesModal')
    },
    pullSales () {
      if (!this.selectedDate) {
        return
      }
      this.waiting = true
      this.offset = 0
      this.totalResults = 0
      this.progress = 'Processed: 0'
      this.done = false
      var message = {
        date: dateFormat(this.selectedDate, 'isoDate'),
        limit: this.limit,
        offset: this.offset
      }
      this.$http.post(this.server + '/myapp/pull-sales-for-date/', message).then(resp => {
        this.requestSalesCallback(resp.body)
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    requestSalesCallback (data) {
      if (data.hasMore) {
        this.offset = this.offset + this.limit
        this.totalResults = data.totalResults
        this.progress = 'Processed: ' + this.offset + ', total: ' + this.totalResults
        var message = {
          date: dateFormat(this.selectedDate, 'isoDate'),
          limit: this.limit,
          offset: this.offset
        }
        this.$http.post(this.server + '/myapp/pull-sales-for-date/', message).then(resp => {
          this.requestSalesCallback(resp.body)
        }, err => {
          this.error = err.body
          this.waiting = false
        })
      } else {
        this.done = true
        this.progress = 'Done: ' + this.totalResults
        this.waiting = false
      }
    }
  },
}
</script>

<style scoped>
.my-datepicker {
  height: 400px;
}

.from-date-picker {
  padding-right: 0px;
}
.to-date-picker {
  padding-left: 0px;
}
</style>